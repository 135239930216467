import React, { Component } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import NavBar from './NavBar.js';
import Tachyonsocialfooter from './Tachyonsocialfooter.js'

import Home from './Home';
import About from './About';
import Contact from './Contact';
import Skills from './Skills';



class App extends Component {
  render() {
    return (
      <Router>
      <NavBar />
        <div>
            <Route path="/" component={Home} exact/>
            <Route path="/About" component={About}/>
            <Route path="/Skills" component={Skills}/>
            <Route path="/Contact" component={Contact}/>
        </div>

        <div id="bottom"> <hr/>
          <Tachyonsocialfooter></Tachyonsocialfooter>
        </div>
      </Router>

    );
  }
}

export default App;
